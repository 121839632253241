
import { Component, Vue } from 'vue-property-decorator'
import { DicInfo } from '@/types/common'
import { ProjectDetail } from '@/types/project'
import AMapLoader from '@amap/amap-jsapi-loader'
import { mapKey } from '@/utils/config'
import { drawPolygon } from '@/utils/formatData'
import Fullscreen from './Fullscreen.vue'
import { DeviceInfo } from '@/types/overview'

@Component({
  name: 'Overview',
  components: { Fullscreen },
  filters: {
    numberFormat (val: number | string) {
      val = Number(val) || 0
      val = val > 10000 ? (val / 10000).toFixed(1) : val
      return val
    }
  }
})
export default class Overview extends Vue {
  private projectTypeList: Array<DicInfo> = []
  private projectList: Array<ProjectDetail> = []
  private projectResultList: Array<ProjectDetail> = []
  private deviceList: Array<{ longitude: string; latitude: string }> = []
  private currentDevice: DeviceInfo | null = null
  private type = ''
  private map: AMap.Map | null = null
  private mapBlockHeight = 'calc(100% - 50px)'
  private icon = require('@/assets/icon/bracelet/online.svg')
  private searchInfo: { maintUnit: string; projectId: string } = {
    maintUnit: '',
    projectId: ''
  }

  private info = {
    projectCount: '',
    areasSum: '',
    normalAmountSum: '',
    abnormalAmountSum: '',
    workerAmountSum: ''
  }

  get infoTip () {
    const info = this.currentDevice as DeviceInfo
    let tip = ''
    if (info.deviceStatus === '1') {
      tip = info.presentStatus === '0' ? '<div class="tip">在岗</div>' : info.presentStatus === '1' ? '<div class="tip outwork">脱岗</div>' : '<div class="tip">在线</div>'
    } else {
      tip = info.deviceStatus === '2' ? '<div class="tip offline">离线</div>' : ''
    }
    return tip
  }

  created (): void {
    this.getProjectList()
    this.getProjectType()
  }

  destroyed (): void {
    if (this.map) {
      (this.map as any).clearEvents('click')
      this.map.destroy()
    }
  }

  // 获取项目
  getProjectList () {
    this.$axios.get(this.$apis.project.selectProjectByList, {
      projectType: this.type
    }).then((res) => {
      this.projectList = res.list || []
    })
  }

  fullscreenStyleChange (isFull: boolean) {
    this.mapBlockHeight = isFull ? 'calc(100vh - 95px)' : 'calc(100vh - 50px)'
  }

  // 渲染地图
  loadMap (lngLat: [number, number]) {
    return AMapLoader.load({
      key: mapKey,
      version: '2.0',
      plugins: []
    }).then(() => {
      this.$nextTick(() => {
        this.map = new AMap.Map('map', {
          zoom: 14,
          center: lngLat
        })
        this.map.on('click', this.clearInfoWindow)
        this.drawPolygon()
        this.drawMarkers()
        this.map.setCenter(lngLat)
        this.map.setZoom(14)
      })
    })
  }

  typeChange (type: string) {
    if (this.type !== type) {
      this.type = type
      this.getProjectList()
      this.getProjectInfo()
    }
  }

  getProjectType () {
    this.$axios.get(this.$apis.common.selectDicByList, {
      dicType: 'projectType'
    }).then((res) => {
      this.projectTypeList = res.projectType || []
      const projectType = (this.$route.query.projectType as string) || ''
      if (this.projectTypeList && this.projectTypeList.length > 0) {
        const ind = projectType !== '' ? this.projectTypeList.findIndex(item => item.dicCode === projectType) : -1
        if (ind >= 0) {
          this.type = projectType
        } else {
          this.type = this.projectTypeList[0].dicCode
        }
      }
      this.getProjectInfo()
    })
  }

  getProjectInfo () {
    this.$axios.get(this.$apis.overview.selectSumProjectList, {
      projectType: this.type,
      ...this.searchInfo
    }).then((res) => {
      this.info = {
        projectCount: res.projectCount,
        areasSum: res.areasSum,
        normalAmountSum: res.normalAmountSum,
        abnormalAmountSum: res.abnormalAmountSum,
        workerAmountSum: res.workerAmountSum
      }
      this.projectResultList = res.projects || []
      this.deviceList = res.deviceList || []
      if (this.projectResultList.length > 0) {
        const lngLat: [number, number] = [Number(this.projectResultList[0].longitude), Number(this.projectResultList[0].latitude)]
        if (!this.map) {
          this.loadMap(lngLat)
        } else {
          this.map.clearMap()
          this.drawPolygon()
          this.drawMarkers()
          this.map.setCenter(lngLat)
          this.map.setZoom(14)
        }
      }
    })
  }

  drawPolygon () {
    this.projectResultList.forEach(item => {
      drawPolygon(this.map as AMap.Map, item.projectLocationList || [], null, this.clearInfoWindow)
      this.drawLabel(item)
    })
  }

  // 项目中心点文本标记
  drawLabel (item: any) {
    const lngLat: [number, number] = [Number(item.longitude), Number(item.latitude)]
    const label = new AMap.Text({
      text: item.projectName.length > 6 ? item.projectName.substring(0, 6) + '...' : item.projectName, // 标签内容
      position: lngLat, // 标签位置坐标
      cursor: 'pointer'
    })
    // 设置标签样式
    label.setStyle({
      fontSize: '14px', // 字体大小
      fontWeight: 'bold', // 字体粗细
      fillColor: '#ffffff', // 填充颜色
      strokeColor: '#000000', // 边框颜色
      strokeWidth: 2 // 边框宽度
    })
    // 将标签添加到地图上
    ;(this.map as AMap.Map).add(label)
    label.on('click', () => {
      this.toProjectDetail(item.projectId)
    })
  }

  // 地图打点
  drawMarkers () {
    this.deviceList.forEach(item => {
      if (item.longitude && item.latitude) {
        const marker = new AMap.Marker({
          icon: new AMap.Icon({
            // 图标的取图地址
            image: this.icon,
            imageSize: new AMap.Size(27, 36)
          }),
          zIndex: 3,
          offset: new AMap.Pixel(-13.5, -36),
          position: [Number(item.longitude), Number(item.latitude)],
          extData: item
        })
        ;(this.map as AMap.Map).add(marker)
        marker.on('click', this.deviceMarkerClick)
      }
    })
  }

  getDeviceInfo (data: DeviceInfo) {
    return this.$axios.get(this.$apis.overview.selectWorkCardDetail, {
      deviceId: data.deviceId
    }).then(res => {
      this.currentDevice = {
        ...res,
        longitude: data.longitude,
        latitude: data.latitude
      }
    })
  }

  // 点击地图设备点标记
  async deviceMarkerClick (e: any) {
    const data = e.target.getExtData()
    const info = this.currentDevice ? this.currentDevice as DeviceInfo : null
    if (!(info && info.deviceId === data.deviceId)) {
      this.clearInfoWindow()
      await this.getDeviceInfo(data)
      setTimeout(() => {
        this.showInfoWindow()
      }, 10)
    }
  }

  showInfoWindow () {
    this.$nextTick(() => {
      const infoWindow = new AMap.InfoWindow({
        content: this.$refs.infoWindow as any,
        isCustom: true,
        autoMove: true,
        closeWhenClickMap: true,
        anchor: 'bottom-center',
        offset: [0, -40] as any
      })
      const info = this.currentDevice as DeviceInfo
      infoWindow.open(this.map as AMap.Map, [Number(info.longitude), Number(info.latitude)])
    })
  }

  // 清空信息框相关信息
  clearInfoWindow () {
    if (this.currentDevice) {
      this.currentDevice = null
    }
  }

  toProjectDetail (id: string) {
    this.$router.push({ name: 'overviewScreenProject', params: { projectId: id } })
  }
}
